<template>
  <tr>
    <td class="left">{{left}}</td>
    <td class="right"><slot></slot></td>
  </tr>
</template>

<script>
export default {
  name: "InfoTd",
  props:["left"],
};
</script>

<style lang='css' scoped>
tr{
  border-bottom: 1Px solid #f5f5f5;
  font-size: .32rem;
}
td {
  padding: 0.26rem 0 .22rem;
  line-height: 1.5;
  box-sizing: border-box;
}
.left{color: #888;width:2.2rem;}
.right{color: #333;padding-right: .7rem;}
</style>
