<template>
  <!-- 主要内容 -->
  <div class="main-center info-mian-center">
    <!-- 个人基本信息 -->
     <table class="table info-content info-personal" v-if="userType == 1">
        <InfoTd left="真实姓名：">{{ userInfo.name }}</InfoTd>
        <InfoTd left="手机号码：">{{ userInfo.mobile }}</InfoTd>
        <InfoTd left="证件号码：">{{userInfo.id_card}} ({{userInfo.card_type}})</InfoTd>
        <InfoTd style="border:none" left="合格投资者认证：">
          <input
            type="button"
            class="button btn-half-opa"
            disabled
            value="已认证"
        /></InfoTd>
      </table>
    <!-- 公司基本信息 -->
      <table class="table info-content info-company" v-if="userType == 2">
        <InfoTd left="公司姓名：">{{ userInfo.institution_name }}</InfoTd>
        <InfoTd left="手机号码：">{{ userInfo.mobile }}</InfoTd>
        <InfoTd left="社会统一信用代码：">{{ userInfo.social_code }}</InfoTd>
      </table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InfoTd from "./InfoTd";
export default {
  name: "essential-information",
  computed: {
    ...mapState(["userInfo"]),
    userType() {
      return this.userInfo.user_type;
    },
  },
  components: { InfoTd },
};
</script>
<style lang='css' scoped>
.info-mian-center {
  padding-top: 0.3rem;
  padding-left: 0.6rem;
  padding-right: 0;
  font-size: 0.28rem;
  color: #000;
}
</style>
