<template>
  <div class="div-step">
    <div class="step-list step-1 active">
      <div class="img-1">
        <span class="step-img"></span>
        <p>1.填写验证信息</p>
      </div>
      <span class="line"></span>
    </div>
    <div class="step-list step-2" :class="{'active':step2}">
      <span class="line"></span>
      <div class="img-2">
        <span class="step-img"></span>
        <p>2.确认密码</p>
      </div>
      <span class="line"></span>
    </div>
    <div class="step-list step-3">
      <span class="line"></span>
      <div class="img-3">
        <span class="step-img"></span>
        <p>3.找回成功</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepImg",
  props: ["step"],
  data() {
    return {
      step2:false
    };
  },
  mounted() {
  },
  watch:{
    step:{
      immediate:true,
      handler(val){
        if(val>=2){
          this.step2=true;
        }
    }
    }
  },
  methods: {},
  components: {},
};
</script>

<style scoped>
.div-step {
  padding-bottom: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.7rem;
}
.div-step .step-list {
  padding: 0 0.03rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.div-step .step-list p {
  padding-top: 0.1rem;
  word-break: keep-all;
  font-size: 0.24rem;
  color: #999;
}
.div-step .line {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 0.03rem dashed #333;
}
.div-step .step-list.active .line {
  border-top: 0.03rem solid #333;
}
.div-step .step-list.active p {
  color: #90281a;
}
.div-step .step-img {
  display: inline-block;
  width: 0.64rem;
  height: 0.64rem;
  margin: 0 0.2rem;
}
.div-step .step-1.active .step-img {
  background: url(./imgs/icon_1.png) no-repeat;
  background-size: cover;
}
.div-step .step-2 .step-img {
  background: url(./imgs/icon_2_h.png) no-repeat;
  background-size: cover;
}
.div-step .step-2.active .step-img {
  background: url(./imgs/icon_2.png) no-repeat;
  background-size: cover;
}
.div-step .step-3 .step-img {
  background: url(./imgs/icon_3_h.png) no-repeat;
  background-size: cover;
}
.div-step .step-3.active .step-img {
  background: url(./imgs/icon_3.png) no-repeat;
  background-size: cover;
}
</style>
