import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
// //引入store
import store from "@/store";
import { getToken } from '@/utiles/token'

// 引入路由组件
import Home from '@/pages/Home/index.vue'
import Login from '@/pages/Login/index.vue'
import RetrievePassword from '@/pages/RetrievePassword'
// 个人中心
import PersonalCenter from '@/pages/PersonalCenter'
// 我的投资
import MyInvestment from '@/pages/PersonalCenter/MyInestment'
// 消息中心
import MessageCenter from '@/pages/PersonalCenter/MessageCenter'
// 基本信息
import EssentialInformation from '@/pages/PersonalCenter/EssentialInformation'

// 交易记录
import TransactionRecord from '@/pages/PersonalCenter/TransactionRecord'
// 我的投资、交易记录 详情页面 
import InformationInfo from '@/pages/PersonalCenter/InformationInfo'
// 我的投资、交易记录 信息披露
import InformationDisclosure from "@/pages/PersonalCenter/InformationDisclosure"



//需要重写VueRouter.prototype原型对象身上的push|replace方法
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject);
    } else {
        originPush.call(this, location, () => { }, () => { });
    }
};
//重写VueRouter.prototype身上的replace方法了
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject);
    } else {
        originReplace.call(this, location, () => { }, () => { });
    }
};

// 配置路由
const router = new VueRouter({
    // HTML5 History 模式
    // mode: 'history', //打包想买的时候须要注释掉，否则页面刷新路径报错。
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        {
            path:"/",
            redirect: { name: 'home'}
        },
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta:{
                title:"首页"
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta:{
                title:"登录"
            }
        },
        {
            path: '/retrieve-password',
            name: 'retrieve-password',
            component: RetrievePassword,
            meta:{
                title:"找回密码"
            }
        }, 
        {
            path: '/personal-center',
            name: 'personal-center',
            redirect: { name: 'my-investment' },
            component: PersonalCenter,
            children: [
                {
                    path: 'my-investment',
                    name: 'my-investment',
                    component: MyInvestment,
                    meta:{
                        title:"我的投资"
                    }
                },
                {
                    path: 'message-center',
                    name: 'message-center',
                    component: MessageCenter,
                    meta:{
                        title:"消息中心"
                    }
                },
                {
                    path: 'transaction-record',
                    name: 'transaction-record',
                    component: TransactionRecord,
                    meta:{
                        title:"交易记录"
                    }

                },
                {
                    path: 'essential-information',
                    name: 'essential-information',
                    component: EssentialInformation,
                    meta:{
                        title:"基本信息"
                    }
                },
                {
                    path: 'information-info/:path/:id'
                    , name: 'information-info'
                    , component: InformationInfo,
                    meta:{
                        title:"详情"
                    }


                },
                {
                    path: 'information-disclosure/:path/:id',
                    name: "information-disclosure",
                    component: InformationDisclosure,
                    meta:{
                        title:"信息披露"
                    }
                },
                {
                    path: '*',
                    redirect: 'my-investment',
                }
            ]
        },

        {
            path: '*',
            redirect: '/'
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    let toPath = to.path;
    let loginInfo = getToken();
    let { access_token, expires_in, loginTime } = loginInfo;
    let now = new Date().getTime();
    if (access_token == "" || ((expires_in * 1000 + loginTime) - now <= 0)) {
        // 未登录或者token过期
        // 不可以进入个人中心页面
        if (toPath.indexOf('/personal-center') != -1) {
            alert("请先登录")
            next("/login")
        } else { next() }
    } else if ((expires_in * 1000 + loginTime) - now <= 60000) {
        store.dispatch("refresh", { "token": access_token })
        next();
    } else {
        // 已登录，且不需要刷新token
        // 不可以进入登录和找回密码页
        if (to.path == "/login" || toPath.indexOf('/retrieve-password') != -1) {
            next("/")
        } else {
            next()
        }
    }
})
router.afterEach(to => {
    if(to.meta.title){document.title = to.meta.title}
    if(to.params.path=="transaction-record" && to.meta.title=="详情"){
        document.title = "交易详情"
    }
    if(to.params.path=="my-investment" && to.meta.title=="详情"){
        document.title = "投资详情"
    }    
    
})

export default router;
