<template>
  <div class="box-login box-back-psd clearfix">
    <StepImg step="2" />
    <p class="error-msg" v-show="errMsg">{{ errInfo }}</p>
    <form class="frm-login">
      <!-- 密码 -->
      <InputText
        label="密码"
        type="password"
        placeholder="请输入6-12位数字与字母组合"
        require="true"
        @inputChange="(res) => (pwd = res)"
        @handlerErr="handlerErr"
      />
      <!-- 密码确认 -->
      <InputText
        label="确认密码"
        type="password"
        placeholder="请再次输入登录密码"
        require="true"
        @inputChange="(res) => (resPwd = res)"
        @handlerErr="handlerErr"
      />
      <InputButton text="下一步" @submitClick="next" />
    </form>
  </div>
</template>

<script>
import StepImg from "./StepsImg";
import InputText from "@/components/InputText.vue";
import InputButton from "@/components/InputButton.vue";
export default {
  name: "Step2",
  components: { StepImg, InputText, InputButton },
  data() {
    return {
      errMsg: false,
      errInfo: "",
      resPwd: "",
      pwd: "",
    };
  },

  methods: {
    //点击进入下一部
    next() {
      let pwd = this.pwd.trim(),
        resPwd = this.resPwd.trim();

      if (pwd != resPwd) {
        this.showErrorInfo("两次输入的密码不一致！");
        return false;
      }
      if (!this.checkPassword(pwd)) {
        this.showErrorInfo("密码由6-18位英文,数字或字符（包含!@#$%^*_）组成!");
        return false;
      }
      this.$emit("goStepOk", { pwd, resPwd });
    },
    // 输入框获取焦点错误提示隐藏
    handlerErr() {
      this.errMsg = false;
      this.errInfo = "";
    },
    // 错误信息提示
    showErrorInfo(err) {
      this.errInfo = err;
      this.errMsg = true;
    },
    /**
     * 验证密码格式
     * @param  string 	password 密码
     * @return boolean	true/flase
     */
    checkPassword(password) {
      return password.match(/[\w]([!@#$%^*]?){6,18}$/);
    },
  },
};
</script>

<style lang='css' scoped>
.frm-login {
  width: 85%;
  padding: 0.26rem 0.5rem 0;
}
.error-msg {
    position: absolute;
    margin: 0;
    color: #f84949;
    text-align: center;
    line-height: 1;
    top: 2.3rem;
    width: 100%;
}
</style>
