import Vue from 'vue'
import App from './App.vue'
import 'vant/lib/index.css';
import '@/utiles/vant'
Vue.config.productionTip = false
// 引入路由
import router from '@/router'
import store from './store';

// 引入时间戳转换插件
import {dateFormat} from '@/utiles/dateFormat'
// 注册成管道符
Vue.filter("dateFormat",(value, formatString)=>dateFormat(value, formatString));
// 清空token和本地数据
import {removeToken} from '@/utiles/token'
Vue.prototype.$removeToken =removeToken 
import httpService from "@/api/request";
Vue.config.productionTip = false
Vue.prototype.$http = httpService // ajax请求方法

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
