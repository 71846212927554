<template>
  <div class="box-account-info box-shadow">
        <router-link class="item-invest" :to="{name:'my-investment'}" :class="{active:$route.path.indexOf('investment')!=-1}">我的投资</router-link>
        <router-link class="transaction-record" :to="{name:'transaction-record'}" :class="{active:$route.path.indexOf('transaction')!=-1}">交易记录</router-link>
        <router-link class="item-msg" :to="{name:'message-center'}" :class="{active:$route.path.indexOf('/message-center')!=-1}">消息中心</router-link>
        <router-link class="item-info" :to="{name:'essential-information'}" :class="{active:$route.path.indexOf('/essential-information')!=-1}">基本信息</router-link>
  </div>
</template>

<script>
export default {
  name: "CenterNav",
};
</script>

<style scoped>

.box-account-info{
  width: 100%;
  height: .98rem;
  display: flex;
  align-items: stretch;
  justify-content: center;
  border-top: .01rem solid #f5f5f5;
  border-bottom: .01rem solid #f5f5f5;
  box-shadow: 0 .01rem .04rem 0 rgba(0,0,0,0.08); 
  box-sizing: border-box;
}
a {
  flex: 1;
  align-self: stretch;
  display: block;
  line-height:.98rem ;
  text-align: center;
  position: relative;
  font-size: 0.32rem;
  color: #777;
}
a.active {
  color: #90281a;
  font-weight: bold;
  background: #f9f1ed;
}
a.active::after {
  color: #31373d;
  font-weight: normal;
}

</style>
