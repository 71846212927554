<template>
  <div class="box-login box-back-psd clearfix">
    <StepImg step="1" />
    <p class="error-msg" v-show="errMsg">{{ errInfo }}</p>
    <form class="frm-login">
      <!-- 手机号 -->
      <InputText
        label="手机号"
        type="text"
        placeholder="请输入手机号"
        require="true"
        @inputChange="(res) => (mobile = res)"
        @handlerErr="handlerErr"
      />
      <!--图形验证码 -->
      <ImgCode
        label="图形验证码"
        type="text"
        placeholder="请输入图形验证码"
        require="true"
        @inputChange="(res) => (img_code = res)"
        :captcha="captcha"
        @handlerErr="handlerErr"
        @getImgCode="getImgCode"
      />
      <!-- 短信验证码 -->
      <InputCode
        label="短信验证码"
        type="text"
        placeholder="请输入验证码"
        require="true"
        :coolTime="coolTime"
        @inputChange="(res) => (code = res)"
        @handlerErr="handlerErr"
        @sendNoteCode="sendNoteCode"
      />
      <!-- 点击下一步 -->
      <InputButton text="下一步" @submitClick="next" />
    </form>
  </div>
</template>

<script>
import StepImg from "../StepsImg";
import InputText from "@/components/InputText.vue";
import InputCode from "./InputCode.vue";
import ImgCode from "./ImgCode.vue";
import InputButton from "@/components/InputButton.vue";
export default {
  name: "Step1",
  components: { StepImg, InputText, InputCode, InputButton, ImgCode },
  data() {
    return {
      errMsg: false,
      errInfo: "",
      mobile: "",
      code: "",
      captcha: "",
      client: "",
      img_code: "",
      coolTime: 0,
    };
  },
  created() {
    // 初次进入组件限申请一次图形验证码
    this.getImgCode();
  },
  watch:{
    mobile:{
      handler(val){
        this.$emit('changeVal',{'mobile':val})
      }
    },
    img_code:{
      handler(val){
        this.$emit('changeVal',{'img_code':val})
      }
    },
    code:{
      handler(val){
        this.$emit('changeVal',{'code':val})
      }
    }

  },
  methods: {
    //  点击获取短信验证码
    async sendNoteCode() {
      try {
        // 先验证图形验证码对错
        let { client, img_code, mobile } = this;
         // 判断手机号是否正确
        if (!this.checkMobile(mobile)) {
          return this.showErrorInfo("请输入正确的手机号！");
        }
        if (img_code.length != 4) {
          this.showErrorInfo("图形验证码输入错误！");
          return this.getImgCode();
        }
        let result = await this.$store.dispatch("checkImgCode", {
          img_code,
          client,
        });
        if (result.code == 0) {
          this.showErrorInfo(result.msg);
          return this.getImgCode();
        }
        await this.getNoteCode(mobile);
      } catch (error) {
        this.showErrorInfo(error)||"";
        this.getImgCode();
      }
    },
    //获取短信验证码
    async getNoteCode(mobile) {
      try {
        let result = await this.$store.dispatch("sendCode", { mobile });
        let { coolTime } = result;
        if (coolTime == 90) {
          this.coolTime = coolTime;
        }
        if (coolTime < 90 && coolTime > 0) {
          this.coolTime = coolTime;
        }
        return result;
      } catch (error) {
        let {coolTime} = error.data||'';
        if(coolTime){
          this.coolTime = coolTime;
        }
        return Promise.reject(error.msg);
      }
    },
    // 获取图形验证码
    async getImgCode() {
      let result = await this.$store.dispatch("getImgCode");
      this.client = result.client;
      this.captcha = result.captcha;
    },
    // 输入框获取焦点错误提示隐藏
    handlerErr() {
      this.errMsg = false;
      this.errInfo = "";
    },
    //点击进入下一部
    async next() {
      try {
        let mobile = this.mobile.trim(),
        code = this.code.trim();

      if (!this.checkMobile(mobile)) {
        this.showErrorInfo("请输入正确的手机号！");
        return false;
      }
      if (code.length != 6) {
        return this.showErrorInfo("请输入正确的验证码！");
      }
      let res = await this.$http({url: "/resetPwdOne", method: 'post',params: {mobile,code}})
      if(res.code==0){ return this.showErrorInfo(res.msg)}
      this.$emit("goStep2", { mobile, code });
      } catch (error) { console.log(error)  }
    },
    // 错误信息提示
    showErrorInfo(err) {
      this.errInfo = err;
      this.errMsg = true;
    },
    /**
     * 验证手机号码格式
     * @param  string 	mobile 手机号码
     * @return boolean	true/false
     */
    checkMobile(mobile) {
      return mobile.match(/^1[3-9][0-9]\d{8}$/);
    },
  },
};
</script>

<style lang='css' scoped>
.frm-login {
  width: 96%;
  padding: 0.26rem 0 0;
  margin: 0 auto;
}
.error-msg {
  position: absolute;
    margin: 0;
    color: #f84949;
    text-align: center;
    line-height: 1;
    top: 2.3rem;
    width: 100%;
}
</style>
