<template>
  <input :class="type" type="button" :value="value" @click="handler" />
</template>

<script>
export default {
  props: ["value", "type"],
  name:'input-btn',
  data() {
    return {
      content:false
    };
  },
  methods: {
      handler(){
          this.$emit("handler")
      }
  },
};
</script>

<style lang='css' scoped>
input{padding: .14rem .25rem;font-size: .28rem;}
.default{color: #333;background: #f7f7f7;border: 1px solid #dddddd;}

</style>
