<template>
  <div class="video-bg">
    <!-- 视频背景 -->
     <video  class="bg-dideo" ref="bgvideo" preload="auto" loop playsinline autoplay src="./images/bgVideo.mp4" tabindex="-1" muted="muted"></video>
  </div>
</template>

<script>
export default {
  props: ["bgname"],
  mounted() {
    if (this.bgname == "personal-center") {
      this.$nextTick(() => {
        this.$refs.bgvideo.style.top = "0";
      });
    } else {
      this.$nextTick(() => {
        this.$refs.bgvideo.style.top = "50%";
      });
    }
  },
};
</script>

<style scoped>
/* 视频背景 */
.video-bg{
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: -11;
}
video.bg-dideo {
  position: absolute;
  height: 100vh;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
video.bg-dideo source {
  width:100vw
}
</style>
