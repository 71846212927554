<template>
  <div class="action_box">      
    <div v-if="token">
        <router-link class="fir-ini" :to="{ name: 'my-investment' }"
        >我的投资</router-link
      >
      |
      <router-link class="fir-ini" to="/personal-center/message-center"
        >消息（{{ messageCount }}）</router-link
      >
      |
      <a class="fir-ini" href="javascript:;" @click="logout">退出</a>
    </div>
    <div v-else>
<router-link class="fir-ini" id="btnLogin" to="/login">登录</router-link>
    |
    <router-link class="fir-ini btnBackPsd"  :to="{ name:'retrieve-password'}"
      >找回密码</router-link
    >
      </div>
  </div>
</template>

<script>
export default {
    props:["token","messageCount"],
  components: {},
  methods: {
      logout(){
          this.$emit("userLoginOut")
      }
  },
};
</script>

<style scoped lang='css'>
.action_box {
  text-align: right;
  padding: 0.56rem;
  font-size: 0.28rem;
  color: #333;
}
.action_box a {
  color: #333;
}
</style>
