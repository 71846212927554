// 直接更新state的多个方法的对象
import { setToken, removeToken } from '@/utiles/token'
import Vue from 'vue';
export default {
    //登录信息,刷新token
    USERLOGIN(state, userLogin) {
        let loginTime = new Date().getTime();
        userLogin.loginTime = loginTime
        state.userLogin = userLogin;
        setToken(userLogin);
    },
    // 未读消息
    MESSAGECOUNT(state, messageCount) {
        state.messageCount = messageCount;
    },
    // 退出登录
    LOGOUT(state) {
        removeToken();
        state.userLogin = {};
        state.userInfo = {};
        state.investmentList = {};
        state.investmentInfo = {};
        state.announceList = {};
        state.messageList = {};
        state.messageCount = 0;
    },
    // 清空用户信息
    CLEARINFO(state) {
        removeToken();
        state.userLogin = {};
    },
    // 用户信息
    USERINFO(state, userInfo) {
        state.userInfo = userInfo;
    },
    // 我的投资列表
    INVESTMENT(state, investmentList) {
        // 当前页是第一页
        if (investmentList.currentPage == 1) {
            state.investmentList = investmentList;
        } else {
            // 当前页不是第一页
            var data = state.investmentList.data || [];
            investmentList.data.forEach((item) => {
                let Index = data.length;
                Vue.set(data, Index, item);
            })
            state.investmentList.data = data;
            //  更新当前页
            state.investmentList.currentPage = investmentList.currentPage
            // 更新最后一页
            state.investmentList.lastPage = investmentList.lastPage
            // 更新总条数
            state.investmentList.total = investmentList.total
        }

    },
    // 投资详情
    INVESTMENTINFO(state, investmentInfo) {
        state.investmentInfo = investmentInfo;
    },
    // 交易记录
    TRANSITIONLIST(state, transitionList) {
        if (transitionList.currentPage == 1) {
            state.transitionList = transitionList;
        } else {
            // 当前数据不是第一页
            var data = state.transitionList.data || [];
            transitionList.data.forEach((item) => {
                let Index = data.length;
                Vue.set(data, Index, item);
            })
            state.transitionList.data = data;
            //  更新当前页
            state.transitionList.currentPage = transitionList.currentPage
            // 更新最后一页
            state.transitionList.lastPage = transitionList.lastPage
            // 更新总条数
            state.transitionList.total = transitionList.total

        }

    },
    // 交易记录详情
    TRANSATIONINFO(state, transactionInfo) {
        state.transactionInfo = transactionInfo
    },
    // 信息披露
    ANNOUNCELIST(state, announceList) {
        state.announceList = announceList
    },
    // 消息中心列表
    MESSAGELIST(state, messageList) {
        var data = null
        if (messageList.currentPage == 1) {
            // 处理消息列表
            data = [];
        } else {
            data = state.messageList.data;
        }
        // 更新当前页
        state.messageList.currentPage = messageList.currentPage
        // 更新最后一页
        state.messageList.lastPage = messageList.lastPage
        // 更新总条数
        state.messageList.total = messageList.total
        messageList.data.forEach(item => {
            let Index = data.length;
            // let radomNum = Math.random();
            // 添加未多选框选中属性
            Vue.set(item, "title", (item.title));
            Vue.set(item, "checked", false);
            Vue.set(data, Index, item);
        });
        state.messageList.data = data;

        // let data = state.messageList.data
    },
    // 消息详情
    MESSAGEINFO(state, messageInfo) {
        state.messageInfo = messageInfo
    }

}