<template>
  <div id="app" v-cloak>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState(["userLogin"]),
  },  
};
</script>
<style lang='css'>
[v-cloak] {
  display: none;
}
html,
body,
#app {
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  font-family: pingfang SC,helvetica neue,arial,hiragino sans gb,microsoft yahei ui,microsoft yahei,simsun,sans-serif!;
}
#app {
  position: relative;
}
.page-bottom {
  position: -webkit-fixed;
  position: -moz-fixed;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1.5rem;
  text-align: center;
  color: #fff;
  font-size: 0.24rem;
  line-height: 1.8;
}
 .page-bottom  a{color: #fff;display: block;}
</style>