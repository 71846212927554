<template>
  <div class="input-group">
    <label class="col-form-label">
      <!-- 必选的星号 -->
      <span class="star" v-if="require">*</span>{{ label }}</label
    >
    <div class="btn-box">
      <!-- 验证码输入框 -->
      <input
        class="input-code"
        :type="type"
        :placeholder="placeholder"
        v-model="content"
        @focus="handlerErr"
      />
      <!-- 发送验证码按钮 -->
      <input
        class="btn-code sendCode"
        type="button"
        :disabled="disabled"
        v-model="btnText"
        @click="sendCode"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputCode",
  props: ["label", "type", "placeholder", "require", "coolTime"],
  data() {
    return {
      // 验证码
      content: "",
      // 按钮文字
      btnText: "获取",
      // 获取验证码按钮是否禁用
      disabled: false,
      time:0,
    };
  },
  watch: {
    content() {
      this.changeInput();
    },
    coolTime: {
      immediate: true,
      handler(val) {
        if (val <= 90 && val > 0) {
          this.time = val;
          this.timer()
        }
      },
    },
  },
  methods: {
    // 获取验证码并验证处理
    changeInput() {
      this.$emit("inputChange", this.content.trim());
    },
    // 输入框获取焦点隐藏错误提示信息
    handlerErr() {
      this.$emit("handlerErr");
    },
    //发送验证码按钮
    sendCode() {
      this.$emit("sendNoteCode");
    },
    // 倒计时
    timer() {
     return setTimeout(() => {
        if (this.time == 1) {
          clearTimeout(this.timer);
          this.disabled = false;
          this.time=0;
          this.btnText = "获取";
        } else {
          // 按钮禁用
          this.disabled = true;
          // 倒计时描述-1
          this.time--;
          // 输入框显示倒计时时间
          this.btnText = this.time + "s";
          // 递归调用自己
          this.timer();
        }
      }, 1000);
    },
  },
};
</script>
<style lang="css" scoped>
.input-group {
  padding-bottom: 0.3rem;
  width: 100%;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-box {
  width: 4.53rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-group .col-form-label {
  padding-right: 0.3rem;
  color: #333;
  text-align: right;
  display: block;
  width: 6em;
}
.input-group input {
  display: block;
  padding: 0 0.16rem;
  height: 0.8rem;
  background: #fff;
  border: 1Px solid #b0b0b0;
  border-radius: .04rem;
  box-sizing: border-box;
}
.input-group input.input-code {
  width: 2.6rem;
}
.input-group input.btn-code {
  color: #6c1e14;
  border-color: #6c1e14;
  margin-left: 0.1rem;
}
.star {
  color: #6c1e14;
}
.sendCode {
  width: 1.9rem;
}
</style>
