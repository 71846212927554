<template>
  <div class="logo">
    <img src="@/assets/logo_.png" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  components: {},
};
</script>

<style  lang="css" scoped>
.logo {
  height: 0.78rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 2.8rem;
}
.logo img {
  display: block;
  height: 100%;
}
</style>
