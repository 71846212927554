<template>
  <div class="box-login box-back-psd clearfix">
    <form class="frm-login">
      <div class="info-success">
        <span class="icon-suc"></span>
        <p class="name-suc">恭喜，{{mobile}}</p>
        <p class="text-suc">找回密码成功</p>
      </div>
      <p style="width: 4.2rem; margin: 0 auto">
        <InputButton text="去登录" showLabel="hide" @submitClick="goLogin" />
        <span class="goto-login col_969696">
          {{ times }} 秒后 直接跳转到登录页面</span
        >
      </p>
    </form>
  </div>
</template>

<script>
import InputButton from "@/components/InputButton.vue";
export default {
  name: "Step3",
  props:['mobile'],
  components: { InputButton },
  data() {
    return { times: 3, showLabel: 1 };
  },
  mounted() {
    this.timer();
    console.log()
  },
  methods: {
    // 去登录
    goLogin() {
      clearTimeout(this.timer);
      this.$router.push("/login");
    },
    timer() {
      return setTimeout(() => {
        if (this.times <= 1) {
          clearTimeout(this.timer);
          this.$router.push("/login");
        } else {
          this.times--;
          // 递归调用自己
          this.timer();
        }
      }, 1000);
    },
  },
};
</script>

<style lang='css' scoped>
.frm-login {
  width: 85%;
  padding: 0.26rem 0.5rem 0;
}
/* 密码找回成功 */
.info-success {
  text-align: center;
  padding-bottom: 0.24rem;
}
.icon-suc {
  margin-bottom: 0.15rem;
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  background: url(./StepsImg/imgs/icon_3.png) no-repeat;
  background-size: cover;
}
.goto-login {
  padding-top: 0.33rem;
  display: block;
  text-align: center;
}
.name-suc {
  padding-bottom: 0.15rem;
  color: #666;
}
.text-suc {
  font-size: 0.38rem;
  color: #333;
  font-weight: bold;
}
</style>
