<template>
  <div class="personal-center">
    <!-- 顶部 -->
    <Header />
    <div class="msg-content">
      <!-- 用户信息 -->
      <UserInfo />
      <!-- 账户管理 -->
      <CenterNav />
      <!-- 页面内容 -->
      <div class="personal_box">
        <router-view></router-view>
      </div>
    </div>
    <!-- 背景视频 -->
    <BgVedio bgname="personal-center" />
  </div>
</template>

<script>
import Header from "@/components/Header";
import BgVedio from "@/components/BgVedio";
import UserInfo from "./UserInfo";
import CenterNav from "./CenterNav";

import { mapGetters, mapState } from "vuex";
export default {
  name: "personal-center",
  components: { Header, BgVedio, UserInfo, CenterNav },
  computed: {
    ...mapGetters(["token"]),
    ...mapState(["userInfo"]),
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    // 请求用户信息
    async getUserInfo() {
      let { token } = this;
      await this.$store.dispatch("userInfo", { token });
    },
  },
};
</script>

<style lang='css'>
.personal-center {
  width: 100%;
  height: 100%;
}
.msg-content {
  min-height: 90vh;

  background: #fff;
}
.personal_box {
  flex: 1;
  border-top: 0.24rem solid #f9f9f9;
  width: 100%;
  box-sizing: border-box;
}
.personal_box::-webkit-scrollbar {
  display: none;
}
/* 表格 */
.table {
  width: 100%;
  font-size: .28rem;
  color: #333;
}
</style>
