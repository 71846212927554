<template>
  <van-pull-refresh
    v-model="isLoading"
    animation-duration="300"
    @refresh="onRefresh"
  >
    <div class="info-content">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="onLoad"
      >
        <inputBtn type="default" value="标为已读" @handler="tobeRead" />
        <table class="table">
          <thead>
            <CenterHead>
              <th class="col-check">
                <div class="box-checked">
                  <input
                    type="checkbox"
                    v-model="checkedAll"
                    :disabled="disabled"
                    @change="handlerCheck"
                  />
                </div>
              </th>
              <th style="width: 45%">标题</th>
              <th style="width: 30%">发送时间</th>
              <th style="width: 15%">操作</th>
            </CenterHead>
          </thead>
          <tbody>
            <CenterTd
              v-for="(info, index) in list"
              :key="index"
              :class="{ readOver: info.state == 1 }"
              :item="info"
              @handlercheckInfo="handlercheckInfo"
              @handlerCheck="handlerTdCheck"
            ></CenterTd>
          </tbody>
        </table>
      </van-list>
    </div>
    <MessageLayer
      v-show="showDetail"
      :MessageLayerInfo="MessageLayerInfo"
      :MessageLayerId="MessageLayerId"
      @closeDetail="closeDetail"
    />
  </van-pull-refresh>
</template>

<script>
// 标记已读按钮
import inputBtn from "../inputBtn.vue";
// 表格头部
import CenterHead from "../CenterHead";
// 表格td
import CenterTd from "./CenterTd";
// 消息弹窗
import MessageLayer from "./MessageLayer.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "message-center",
  components: { inputBtn, CenterHead, CenterTd, MessageLayer },
  data() {
    return {
      // 信息弹窗是否弹出
      showDetail: false,
      MessageLayerId: 0,
      MessageLayerInfo: {},
      // 全选按钮状态
      checkedAll: false,
      currentPage: 0,
      lastPage: 0,
      total: 0,
      list: [],
      isLoading: false,
      loading: false,
      finished: false,
      finishedText: "数据加载完毕！",
      disabled: false,
    };
  },
  computed: {
    ...mapGetters(["token"]),
    ...mapState(["messageList"]),
  },
  watch: {
    list: {
      immediate: true,
      handler(val) {
        if (val.length == 0) {
          this.disabled = true;
        } else {
          this.disabled = val.every((item) => item.state == 1);
        }
      },
    },
  },
  mounted() {
    if (this.list.length == 0) {
      this.disabled = true;
    }
  },
  methods: {
    // 滚动加载
    async onLoad() {
      try {
        let { token, currentPage } = this;
        if (currentPage == 0) {
          currentPage = 1;
        } else {
          currentPage++;
        }
        await this.getMessageList({ token, page: currentPage });
        this.loading = false;
        this.isLoading=false;
        if (this.list.length >= this.total) {
          this.finished = true;
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    // 下拉刷新
    async onRefresh() {
      this.list = [];
      this.currentPage = 0;
      this.isLoading = true;
      this.finished = false;
      await this.onLoad();
    },
    // 关闭遮罩层
    closeDetail(id) {
      this.showDetail = false;
      this.$store.state.messageList.data.forEach((item) => {
        if (item.id == id) {
          // 更新数据已读状态
          this.$set(item, "state", 1);
        }
      });
    },
    // 更新数据
    updataMessageList(messageList) {
      this.currentPage = messageList.currentPage;
      this.lastPage = messageList.lastPage;
      this.total = messageList.total;
      this.list = messageList.data;
    },
    // 获取消息列表数据
    async getMessageList(data) {
      try {
        // 派发获取消息列表actions
        await this.$store.dispatch("messageList", data);
        // 数据成功返回后更新更新本地数据
        this.updataMessageList(this.messageList);
      } catch (error) {
        if (error == "未查到消息记录") {
          this.loading = false;
          this.finished = true;
          this.finishedText = "暂无数据！";
        }
        // this.$toast(error);
      }
    },
    // 点击查看信息
    handlercheckInfo(id) {
      id && this.messageInfo(id);
    },
    // 查看消息请求
    async messageInfo(id) {
      try {
        let { token } = this;
        // 派发获取消息列表actions
        await this.$store.dispatch("messageInfo", { token, id });
        // 数据请求成功，更新数据
        this.MessageLayerInfo = this.$store.state.messageInfo;
        this.MessageLayerId = id;
        this.showDetail = true;
      } catch (error) {
        this.$toast(error);
      }
    },
    // 点击标记已读按钮
    async tobeRead() {
      try {
        let { token } = this,
          id = this.chekedNum().idList;
        if (id.length < 1) {
          return this.$toast("没有选中消息");
        }
        let idStr = null;
        id.length <= 1 ? (idStr = id.toString()) : (idStr = id.join(","));
        if (idStr && token) {
          await this.$store.dispatch("messageState", { token, id: idStr });
          // 修改本地数据
          this.changeInfoState(id);
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    // 修改消息状态
    changeInfoState(idary) {
      let id = idary;
      this.$store.state.messageList.data =
        this.$store.state.messageList.data.forEach((item) => {
          if (id.indexOf(item.id) != -1) {
            this.$set(item, "state", 1);
            this.$set(item, "checked", false);
            return item;
          } else {
            return item;
          }
        });
    },
    // 操作数据是否全选
    UnReadItem(isCheck) {
      this.$store.state.messageList.data.forEach((item) => {
        if (item.state == 0) {
          this.$set(item, "checked", isCheck);
        }
      });
    },
    // 点击全选按钮
    handlerCheck() {
      let checked = this.checkedAll;
      this.UnReadItem(checked);
    },
    // 未读消息数量
    unReadNum() {
      return this.$store.state.messageList.data.filter(
        (item) => item.state == 0
      );
    },
    //已选中的消息信息： length：状态 ,idList：id数组
    chekedNum() {
      let checkIdList = [];
      let newArr = this.$store.state.messageList.data.filter((item) => {
        item.checked == true && checkIdList.push(item.id);
        return item.checked == true;
      });
      return { length: newArr.length, idList: checkIdList };
    },
    // 修改单个多选框状态
    handlerTdCheck(item) {
      let { id, checked } = item;
      this.$store.state.messageList.data.forEach((item) => {
        if (item.state == 0 && item.id == id) {
          this.$set(item, "checked", checked);
        }
      });
      this.unReadNum().length == this.chekedNum().length? (this.checkedAll = true): (this.checkedAll = false);
    },
  },
};
</script>

<style lang='css' scoped>
.info-content {
  height: 100%;
  padding: 0.3rem;
  box-sizing: border-box;
  overflow-y: scroll;
}
.table {
  margin-top: 0.4rem;
  box-sizing: border-box;
}
</style>
