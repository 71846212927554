<template>
  <tr   @click="checkInfo">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "CenterTd",
  props:["item"],
  mounted() {
  },
  methods: {
    checkInfo(){
      this.$emit("checkInfo",this.item.id)
    }
  },
};
</script>

<style lang='css' scoped>
tr{border: 1Px solid #f5f5f5;}
td {
  padding: 0.24rem;
  box-sizing: border-box;
}
.col-btn{text-align: center;}
.col-btn a{color: #40ABF9;}
.col-title{text-align: left;}
.col-money{text-align: right;}
/* 消息中心 */
.actions{position: relative; padding-left: .3rem;}
.actions::before{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #90281a;
  width: .1rem;
  height:.1rem;
  border-radius: .1rem;

}
.readOver td{color: #777; }
</style>
