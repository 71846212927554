// 通过操作mutation间接更新state的多个方法的对象
// 这里可以书写业务逻辑，但是不可以直接修改state数据
import {
    reqUserLogin, reqMessageCount, reqLogOut, reqRefresh, reqUserInfo, reqInvestment, reqInvestmentInfo,
    reqTransitionList, reqTransactionInfo, reqAnnounceList, reqMessageList, reqMessageInfo, reqMessageState,
     reqSendCode,reqRePassworld,reqGetImgCode,reqCheckImgCode
} from "@/api";
import router from "@/router";

export default {
 
    // 清空token
    clearToken({ commit }, result) {
        if (result.msg == "token已过期,请重新登录!") {
            commit("CLEARINFO");
            router.push("/login");
        } else {
            return Promise.reject(result.msg)
        }

    },
    // 获取验证码
    async sendCode(_,data) {
        try {
            let result = await reqSendCode(data);
            if(result.code==1){
                return result.data;
            }else{
                return Promise.reject(result);
            }
        } catch (error) {
            return Promise.reject(error);
        }

    },
    // 图形验证码
    async getImgCode(){
       try {
        let result= await reqGetImgCode();
        return result;
       } catch (error) {
        return Promise.reject(error)
       }
    },
    // 验证图形验证码
    async checkImgCode(_,data){
        try {
            let result = await reqCheckImgCode(data);
            return result;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    // 修改密码
   async rePassworld({commit},data){
        try {
            let result = await reqRePassworld(data);
            if (result.code == 1) {
                // 修改密码成功，清除本地信息
                commit("CLEARINFO");
                return result.msg;
            } else if (result.code == 0) {
                return Promise.reject(result.msg)
            }
        } catch (error) {
            return Promise.reject(error);
        }

    },
    //  用户登录
    async userLogin({ commit }, data) {
        try {
            let result = await reqUserLogin(data);
            if (result.code == 1) {
                commit("USERLOGIN", result.data);
                return result.msg;
            } else if (result.code == 0) {
                return Promise.reject(result.msg)
            }
        } catch (error) {
            return Promise.reject(error);
        }

    },
    // 获取消息数量
    async messageCount({ commit }, data) {
        try {
            let result = await reqMessageCount(data);
            if (result.code == 1) {
                commit("MESSAGECOUNT", result.data.msgCount);
                return result.msg;
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    commit("CLEARINFO");
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },
    // 退出登录
    async logout({ commit }, data) {
        try {
            let result = await reqLogOut(data);
            if (result.code == 1) {
                commit("LOGOUT")
                return result.msg;
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    commit("CLEARINFO");
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) { return Promise.reject(error); }
    },
    // 刷新token
    async refresh({ commit }, data) {
        try {
            let result = await reqRefresh(data);
            if (result.code == 1) {
                commit("USERLOGIN", result.data);
                return result.msg
            } else {
                return Promise.reject(result.msg);
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },
    //    请求用户信息
    async userInfo({ commit }, data) {
        try {
            let result = await reqUserInfo(data);
            if (result.code == 1) {
                commit("USERINFO", result.data);
                return result.msg
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    commit("CLEARINFO");
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) {
            return Promise.reject(error);

        }
    },
    // 投资列表
    async investment({ commit }, data) {
        try {
            let result = await reqInvestment(data);
            if (result.code == 1) {
                commit("INVESTMENT", result.data);
                return result.msg
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    commit("CLEARINFO");
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) {
            return Promise.reject(error)
        }
    },
    // 我的投资详情页
    async investmentInfo({ commit }, data) {
        try {
            let result = await reqInvestmentInfo(data);
            if (result.code == 1) {
                commit("INVESTMENTINFO", result.data);
                return result.msg
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    commit("CLEARINFO");
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) {
            return Promise.reject(error)
        }
    },
    // 交易记录-记录列表
    async transitionList({ commit }, data) {
        try {
            let result = await reqTransitionList(data);
            if (result.code == 1) {
                commit("TRANSITIONLIST", result.data);
                return result.msg;
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    commit("CLEARINFO");
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) {
            Promise.reject(error)
        }
    },
    // 交易记录详情
    async transactionInfo({ commit }, data) {
        try {
            let result = await reqTransactionInfo(data);
            if (result.code == 1) {
                commit("TRANSATIONINFO", result.data);
                return result.msg;
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    commit("CLEARINFO");
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) {
            return Promise.reject(error)
        }
    },
    // 信息披露
    async announceList({ commit }, data) {
        try {
            let result = await reqAnnounceList(data);
            if (result.code == 1) {
                commit("ANNOUNCELIST", result.data);
                return result.msg;
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    commit("CLEARINFO");
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) {
            return Promise.reject(error)
        }
    },
    // 获取消息列表
    async messageList({ commit }, data) {
        try {
            let result = await reqMessageList(data);
            if (result.code == 1) {
                commit("MESSAGELIST", result.data);
                return result.msg;
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    commit("CLEARINFO");
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }

    },
    // 查看消息
    async messageInfo({ commit }, data) {
        try {
            let result = await reqMessageInfo(data);
            if (result.code == 1) {
                commit("MESSAGEINFO", result.data);
                return result.msg;
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    commit("CLEARINFO");
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    },
    //修改消息状态
    async messageState(_, data) {
        try {
            let result = await reqMessageState(data);
            if (result.code == 1) {
                return result.msg;
            } else {
                if (result.msg == "token已过期,请重新登录!") {
                    router.push("/login");
                } else {
                    return Promise.reject(result.msg)
                }
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }
}