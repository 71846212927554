<template>
  <div class="box-containt">
    <!-- 未登录状态 -->
    <loginText
      :token="token"
      :messageCount="messageCount"
      @userLoginOut="userLoginOut"
    />

    <HomeLogo />

    <!-- 视频背景 -->
    <BgVedio />

    <div class="page-bottom">
      © 聚焦(北京)私募基金管理有限公司版权所有<a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer"> 京ICP备2022025266号</a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BgVedio from "@/components/BgVedio";
import loginText from "./loginText.vue";
import HomeLogo from "./HomeLogo.vue";
export default {
  name: "home",
  data() {
    return {};
  },
  components: { BgVedio, loginText, HomeLogo },
  computed: { ...mapState(["messageCount"]), ...mapGetters(["token"]) },
  created() {
    this.getMessageCount();
  },
  mounted() {},
  methods: {
    // 退出登录
    async userLoginOut() {
      let { token } = this;
      await this.$store.dispatch("logout", { token });
      this.$router.push("/home");
    },
    // 未读消息数
    async getMessageCount() {
      try {
        let { token } = this;
        if (token) {
           await this.$store.dispatch("messageCount", {
            token: this.token,
          });
        }
      } catch (error) {
        console.log(error )
      }
    },
    // 刷新token
    async getUserInfo() {
      let { token } = this;
      let result = await this.$store.dispatch("refresh", { token });
      console.log(result);
    },
  },
};
</script>
<style scoped lang="css">
.box-containt{position: relative;}
</style>


