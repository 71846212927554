// 状态对象
import { getToken } from '@/utiles/token'
export default {
    // 用户登录信息
    userLogin: getToken() || {},
    // 未读消息数
    messageCount: 0,
    // 用户信息
    userInfo: {},
    // 投资列表
    investmentList: {},
    // 投资详情
    investmentInfo: {},
    // 交易记录列表
    transitionList: {},
    // 交易详情
    transactionInfo: {},
    // 信息披露
    announceList: {},
    // 消息中心列表
    messageList: {},
    // 消息详情
    messageInfo:{}
}
