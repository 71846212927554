<template>
  <tr>    
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "CenterHead",
};
</script>

<style scoped   lang="css">
tr{
height: .78rem;
color: #666;
font-size: .28rem;
background: #f5f5f5;
box-sizing: border-box;
}
</style>
