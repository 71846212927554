<template>
  <tr>
    <td class="col-title">
      <a class="downImg" :href="info.path" :download="info.name"> {{info.name}}</a>    
    </td>
    <td>{{info.announce_date}}</td>
    <td class="col-btn"><a class="downImg" :href="info.path" :download="info.name">下载</a>  </td>
  </tr>
</template>

<script>
export default {
  name: "DisTd",
  props: ["info"],
  mounted() {},
  methods: {
   
  },
};
</script>

<style lang='css' scoped>
tr {
  border: 1px solid #f5f5f5;
}
td {
  padding: 0.2rem;
}
.col-btn {
  text-align: center;
}
.col-btn a {
  color: #40abf9;
}
.col-title {
  text-align: left;
}
.downImg{color: #000;}
</style>
