<template>
  <div class="info-content">
    <table class="table table-invest">
      <tbody>
        <InfoTd left="项目名称:">
          {{ informationInfo.name }}
        </InfoTd>
        <InfoTd left="成立时间:">
          {{ informationInfo.project_start_date }}
        </InfoTd>
        <InfoTd left="状态:">
          {{ informationInfo.product_state }}
        </InfoTd>
        <InfoTd left="当前份额(万):">
          {{ informationInfo.money }}
        </InfoTd>
        <InfoTd
          left="交易类型:"
          v-if="this.$route.params.path == 'transaction-record'"
        >
          {{ informationInfo.type }}
        </InfoTd>
        <InfoTd left="投资时间:">
          {{ informationInfo.invest_date }}
        </InfoTd>
        <InfoTd left="信息披露:" style="border: none;" ><span style="color:#40ABF9;" @click="checkDisclosure">查看</span></InfoTd>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import InfoTd from "../InfoTd";
export default {
  name: "information-info",
  computed: {
    ...mapState(["investmentList"]),
    ...mapGetters(["token"]),
  },
  data() {
    return {
      informationInfo: {},
    };
  },
  created() {
    this.investmentInfo();
  },
  components: { InfoTd },
  methods: {
    // 查看信息披露
    checkDisclosure() {
      let { path} = this.$route.params;
      let id = this.informationInfo.product_id;
      this.$router.push({
        path: `/personal-center/information-disclosure/${path}/${id}`,
      });
    },
    // 获取信息详情
    async investmentInfo() {
      let { token } = this;
      let { path, id } = this.$route.params;
      if (path == "my-investment") {
        //   我的投资
        await this.$store.dispatch("investmentInfo", { token, id });
        this.informationInfo = this.$store.state.investmentInfo;
      } else if (path == "transaction-record") {
        // 交易记录
        await this.$store.dispatch("transactionInfo", { token, id });
        this.informationInfo = this.$store.state.transactionInfo;
      }
    },
  },
};
</script>

<style scoped lang='css'>
.info-content {
  padding-left: 0.5rem;
  box-sizing: border-box;
}
.table > tbody > tr > td {
  text-align: left;
  font-size: 0.28rem;
  color: #000;
}
.table > tbody > tr > td:first-child {
  width: 2.2rem;
  color: #666;
}
.table > tbody > tr > td:last-child {
  padding-right: 0.4rem;
  line-height: 1.5;
}
</style>