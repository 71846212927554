<template>
  <van-pull-refresh
    v-model="isLoading"
    @refresh="onRefresh"
  >
    <div class="main-center">
      <div class="info-content">
        <span class="sec_h">信息披露</span>
        <h2 class="disc-title">{{ product_name }}</h2>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="数据加载完毕！"
          @load="onLoad"
        >
          <table class="table table-disc">
            <thead>
              <CenterHead>
                <th class="col-title">文件名称</th>
                <th style="width: 30%">披露时间</th>
                <th style="width: 20%">操作</th>
              </CenterHead>
            </thead>
            <tbody>
              <DisTd v-for="(info, index) in list" :key="index" :info="info" />
            </tbody>
          </table>
        </van-list>
      </div>
    </div>
  </van-pull-refresh>
</template>

<script>
import CenterHead from "../CenterHead";
import DisTd from "../DisTd";

import { mapGetters, mapState } from "vuex";
export default {
  name: "information-disclosure",
  data() {
    return {
      currentPage: 0,
      lastPage: 0,
      total: 0,
      product_name: "",
      isLoading: false,
      loading: false,
      finished: false,
      list: [],
    };
  },
  components: {
    CenterHead,
    DisTd,
  },
  computed: { ...mapGetters(["token"]), ...mapState(["announceList"]) },
  methods: {
    // 获取信息披露
    async getAnnounceList() {
      try {
        let { token, currentPage } = this,
          { id } = this.$route.params;
        if (currentPage == 0) {
          currentPage = 1;
        } else {
          currentPage++;
        }
        await this.$store.dispatch("announceList", {
          token,
          id,
          page: currentPage,
        });
        this.currentPage = this.announceList.currentPage;
        this.lastPage = this.announceList.lastPage;
        this.total = this.announceList.total;
        this.product_name = this.announceList.product_name;
        this.list = this.announceList.data;
      } catch (error) {
        this.$toast(error);
      }
    },
    // 上拉加载数据
    async onLoad() {
      await this.getAnnounceList();
      // 加载状态结束
      this.loading = false;
      this.isLoading=false;
      // 数据全部加载完成
      if (this.list.length >= this.total) {
        this.finished = true;
      }
    },
    // 下拉刷新
    async onRefresh() {
      this.list = [];
      this.currentPage = 0;
      this.isLoading = true;
      this.finished = false;
      await this.onLoad();
    },
  },
};
</script>

<style scoped>
/* 信息披露 */
.main-center {
  padding: 0.3rem;
}
.disc-title {
  font-size: 0.28rem;
  color: #333;
  font-weight: bold;
  padding-bottom: 0.31rem;
}
.sec_h {
  margin-bottom: 0.4rem;
  display: block;
  width: 1.78rem;
  height: 0.56rem;
  background: #f9f1ed;
  text-align: center;
  line-height: 0.56rem;
  border-radius: 0.04rem;
  font-size: 0.28rem;
  color: #90281a;
  font-weight: bold;
}
</style>
