<template>
  <!-- 主要内容 -->
  <van-pull-refresh
    v-model="isLoading"
    @refresh="onRefresh"
  >
    <div class="info-content">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="onLoad"
      >
        <table class="table table-invest">
          <CenterHead>
            <th style="width: 50%">项目名称</th>
            <th style="width: 30%">当前份额(万)</th>
            <th style="width: 20%">操作</th>
          </CenterHead>
          <tbody>
            <CenterTd
              v-for="item in list"
              :key="item.id"
              :item="item"
              @checkInfo="checkInfo"
            >
              <td class="col-title">
                {{ item.name }}
              </td>
              <td class="col-money">{{ item.money }}</td>
              <td class="col-btn">
               查看
              </td>
            </CenterTd>
          </tbody>
        </table>
      </van-list>
    </div>
  </van-pull-refresh>
</template>
<script>
import { mapState, mapGetters } from "vuex";

import CenterHead from "../CenterHead";
import CenterTd from "../CenterTd";
export default {
  name: "transaction-record",
  data() {
    return {
      isLoading: false,
      loading: false,
      finished: false,
      finishedText:"数据加载完毕！",
      currentPage: 0,
      list: [],
      lastPage: 0,
      total: 0,
    };
  },
  components: { CenterHead, CenterTd },
  computed: {
    ...mapState(["transitionList"]),
    ...mapGetters(["token"]),
  },
  methods: {
    // 查看信息详情页
    checkInfo(id) {
      let path = this.$route.name;
      path &&
        id &&
        this.$router.push({
          path: `/personal-center/information-info/${path}/${id}`,
        });
    },
    // 获取信息列表
    async getTransitionList(data) {
      await this.$store.dispatch("transitionList", data);
      this.currentPage = this.transitionList.currentPage;
      this.lastPage = this.transitionList.lastPage;
      this.total = this.transitionList.total;
      this.list = this.transitionList.data;
    },
    // 下拉刷新
    async onRefresh() {
      this.list = [];
      this.currentPage = 0;
      this.finished = false;
       this.isLoading = true;
      await this.onLoad();
    },
    // 滚动加载
    async onLoad() {
      try {
        let { token, currentPage } = this;
        if(currentPage==0){currentPage=1}else{currentPage++}
        await this.getTransitionList({ token, page: currentPage });
        this.loading = false;
         this.isLoading = false;
        if (this.list.length >= this.total) {
          this.finished = true;
        }
      } catch (error) {
        // this.$toast(error);
        if (error == "未查到交易记录") {
          this.loading = false;
           this.isLoading = false;
          this.finished = true;
          this.finishedText = "暂无数据！";
        }
      }
    },
  },
};
</script>

<style scoped  lang="css">
.info-content {
  padding: 0.4rem 0.3rem 0;
  height: 100%;
  overflow-y: scroll;
}
.table-invest tbody tr td.col-btn {
  color: #40abf9 !important;
}
p.loadingText {
  font-size: 0.24rem;
  margin: 0;
  text-align: center;
  line-height: 0.5rem;
}
</style>
