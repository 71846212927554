<template>
  <van-skeleton title avatar :row="2" :loading="loading">
    <div class="box-user-info">
      <!-- 个人 -->
      <div class="user-img img-person" v-if="userInfo.user_type == 1"></div>
      <!-- 机构 -->
      <div class="user-img img-company" v-if="userInfo.user_type == 2"></div>
      <div class="user-name" v-if="userInfo.user_type == 2">
        {{ userInfo.institution_name }}
        <span>（{{ userInfo.name }}）</span>
      </div>
      <div class="user-name" v-if="userInfo.user_type == 1">
        你好，{{ userInfo.name }}
      </div>
    </div>
  </van-skeleton>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "UserInfo",
  data() {
    return {
       loading: true,
    }
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
     this.loading = false;
  },
};
</script>

<style scoped>
.box-user-info {
  padding-left: 0.18rem;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 1.75rem;
  font-size: 0.2rem;
  background: #fff;
  box-sizing: border-box;
}
.user-img {
  width: 1.08rem;
  height:1.08rem;
  background: #ddd;
}
.img-person {
  background: url(./imgs/userImg.png) no-repeat center center;
  background-size: cover;
}
.img-company {
  background: url(./imgs/organImg.png) no-repeat center center;
  background-size: cover;
}
.user-name {
  padding: 0 0.4rem;
  font-size: 0.32rem;
  font-weight: bold;
}
.user-name span {
  display: block;
  font-weight: normal;
  color: #666;
}
</style>
