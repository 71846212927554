import axios from "axios";
//安装：npm install --save nprogress
import nProgress from "nprogress";
import 'nprogress/nprogress.css';
const requests = axios.create({
    // baseURL: 'http://focus-web.host/api',//本地的
    // baseURL: 'http://www.jujiao.youxuan.com/api',//田晨本地的
    baseURL: 'https://api.focusfunds.com.cn/api',//正式的
    timeout: 5000,//请求超时时间
});
// 请求拦截器
requests.interceptors.request.use((config) => {
    try {
        // 进度条开始
        nProgress.start()
        // config 配置对象，对象里有一个很重要的属性，headers
        return config;
    } catch (error) {
        return Promise.reject(error);
    }
}, (error) => {
    return Promise.reject(error);
});

// 相应拦截器
requests.interceptors.response.use((res) => {
    // 进度条结束
    nProgress.done()
    // 成功的回调：服务期相应数据回来以后，相应拦截器可以检测到，并操作一些事
    return res.data;
}, (err) => {
    // 进度条结束
    nProgress.done()
    // 失败的回调函数
    return Promise.reject(new Error(err))
}, function (error) {
    // 进度条结束
    nProgress.done()
    return Promise.reject(error);
})

export default requests;