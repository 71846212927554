<template>
  <div class="input-group">
    <label class="col-form-label">{{label}}</label>
    <input
      :type="type"
      :placeholder="placeholder"
      v-model="content"
      @focus="handlerErr"
    />
  </div>
</template>

<script>
export default {
  name: "InputText",
  props:["label","type","placeholder","rule"],
  data() {
    return {
        content:''
    };
  },
  components: {},
 watch:{
    content(){
        this.handler()
        
    } 
 },
  methods: {
    //   方法一：给输入框添加input事件
    //   @input="handlerInput"
    //   handlerInput(){}
    handler(){
       let rule =new RegExp(this.rule);
        rule.test(this.content)&&this.$emit("inputChange",this.content);
    },
    handlerErr(){
        this.$emit("handlerErr")
    }
  },
};
</script>
<style lang="css" scoped>
.input-group{padding-bottom: .3rem; width: 100%;font-size: .28rem;display: flex;justify-content:space-between;align-items: center; }
.input-group .col-form-label{padding-right: .3rem; color: #333;text-align: right;display:block;width: 5em;}
 .input-group input{display: block; padding: 0 .16rem; width: 4.2rem;height: .8rem;background: #fff;border: 1PX solid #b0b0b0;border-radius: .04rem;box-sizing: border-box;}   
</style>
