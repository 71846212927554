<template>
  <!-- 消息弹窗 -->
  <div class="self_layer" @click.stop="closeDetail" :data-shadowclose="true">
    <div class="layer_content">
      <div class="layer_title">
        <span class="layer_close" :data-shadowclose="true"></span>
        {{ MessageLayerInfo.title }}
      </div>
      <div class="layer_main">
        <p>{{ MessageLayerInfo.content }}</p>
        <p class="text-right">
          <span>发件人：{{ MessageLayerInfo.send_user }}</span
          >
          <span>发送时间：{{ MessageLayerInfo.send_time|dateFormat("YYYY-MM-DD") }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "message-info",
  props: ["MessageLayerInfo", "MessageLayerId"],
  data() {
    return {};
  },
  methods: {
    closeDetail(event) {
      let { shadowclose } = event.target.dataset;
      shadowclose && this.$emit("closeDetail", this.MessageLayerId);
    },
  },
  components: {},
};
</script>

<style lang='css' scoped>
/* 消息弹窗 */
.self_layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.32rem;
}
.layer_content {
  position: relative;
  width: 6.48rem;
  height: auto;
  background: #fff;
  color: #31373d;
  font-size: 0.28rem;
  border-radius: 0.04rem;
}
.layer_close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  font-weight: normal;
  line-height: 0.7rem;
  display: block;
  width: 0.6rem;
  height: 0.6rem;
  font-size: 0.2rem;
  background: url(./images/close.svg) center center;
  background-size: 80%;
}
.layer_title {
  height: 0.7rem;
  line-height: 0.7rem;
  font-size: .32rem;
  font-weight: bold;
  text-align: center;
  background: #f7f9fa;
  border-radius: 0.04rem 0.04rem 0 0;
}
.layer_main {
  line-height: 1.8;
  padding: 0.25rem 0.5rem;
}
.text-right span {
  display: block;
}
p {
  margin: 0;
}
.text-right {
  text-align: right;
  margin-top: 0.2rem;
}
</style>
