<template>
  <tr>
    <td><input type="checkbox" :disabled="item.state==1" v-model="isCheked"  @change.stop="handlerCheck" /></td>
    <td class="actions"  @click="handlercheckInfo">{{item.title}}</td>
    <td class="text-center">{{ item.send_time | dateFormat("YYYY-MM-DD HH:mm:ss") }}</td>
    <td class="col-btn"  @click="handlercheckInfo"><a href="javascript:;">查看</a></td>
  </tr>
</template>

<script>
export default {
  name: "CenterTd",
  props: ["item"],
  data() {
    return {
      isCheked:false
    }
  },
  watch:{
    item:{
      immediate:true,
      deep:true,
      handler(val){
        this.isCheked=val.checked;
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // 查看消息
    handlercheckInfo() {
      this.$emit("handlercheckInfo",this.item.id);
    },
    // 改变多选框状态
    handlerCheck(){
      this.$emit("handlerCheck",{"id":this.item.id,"checked":this.isCheked})
    }
  },
};
</script>

<style lang='css' scoped>
tr {
  border-bottom: 1px solid #f5f5f5;
}
td {
  padding: 0.24rem;
  box-sizing: border-box;
}
td.col-btn,td.text-center{
  word-break:keep-all;
  padding:.24rem 0;
  text-align: center;
}
.col-btn a {
  color: #40abf9;
}
/* 消息中心 */
.actions {
  position: relative;
  padding-left: 0.3rem;
}
.actions::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background: #90281a;
  width: 0.1rem;
  height: 0.1rem;
  border-radius: 0.1rem;
}
.readOver td {
  color: #777;
}
.readOver .actions::before {
  background: transparent;
}
.text-center{text-align: center;}
</style>
