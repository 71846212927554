<template>
  <tr>
    <td class="left">
      {{left}}
      <span class="right"><slot></slot></span>
    </td>
  </tr>
</template>

<script>
export default {
  name: "InfoTd",
  props:["left"],
};
</script>

<style lang='css' scoped>
tr{
  border: none;
  border-bottom: 1Px solid #f5f5f5;
  font-size: .28rem;
}
td {
  border: none;
  padding: 0.26rem 0 .22rem;
  line-height: 1.5;
  box-sizing: border-box;
}
.left{color: #888;width:2.2rem;}
.right{color: #000;padding-right: .7rem;display: block;}
.btn-half-opa {
  margin-top: 0.1rem;
  width: 1.48rem;
  height: 0.51rem;
  color: #90281a;
  font-weight: bold;
  border-radius: 1rem;
  border: 0.02rem solid #90281a;
}
</style>
