<template>
  <div class="input-group">
    <label class="col-form-label">
      <span class="star" v-if="require">*</span>{{ label }}</label
    >
    <input
      :type="type"
      :placeholder="placeholder"
      v-model="content"
      @focus="handlerErr"
    />
  </div>
</template>

<script>
export default {
  name: "InputText",
  props: ["label", "type", "placeholder", "require"],
  data() {
    return {
      content: "",
    };
  },
  components: {},
  watch: {
    content() {
      this.handler();
    },
  },
  methods: {
    //   方法一：给输入框添加input事件
    //   @input="handlerInput"
    //   handlerInput(){}
    // 试试检测输入框的值，并验证
    handler() {
      this.$emit("inputChange", this.content);
    },
    handlerErr() {
      this.$emit("handlerErr");
    },
  },
};
</script>
<style lang="css" scoped>
.input-group {
  padding-bottom: 0.3rem;
  width: 100%;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-group .col-form-label {
  padding-right: 0.3rem;
  color: #333;
  text-align: right;
  display: block;
  width: 6em;
}
.input-group input {
     display: block;
    padding: 0 0.16rem;
    width: 4.6rem;
    height: 0.8rem;
    background: #fff;
    border: 1PX solid #b0b0b0;
    border-radius: 0.04rem;
    box-sizing: border-box;
}
.star {
  color: #6c1e14;
}
</style>
