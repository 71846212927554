//存储token
export const setToken = (userLogin) => {
  // 登录时间
  localStorage.setItem("USERLOGIN", JSON.stringify(userLogin));
};
//获取token
export const getToken = () => {
  let userLogin = JSON.parse(localStorage.getItem("USERLOGIN"))||{};
  return {
    "access_token": userLogin["access_token"]||"",
    "loginTime": userLogin["loginTime"]||"",
    "expires_in":userLogin["expires_in"]||"",
    "token_type":userLogin["token_type"]||""
  };
};

//清除本地存储的token
export const removeToken = () => {
  localStorage.removeItem("USERLOGIN");
  localStorage.removeItem("TOKENTIME");
}
