<template>
  <div class="page-login">
    <!--顶部 logo -->
    <LogoLogin />

    <!--登录框  -->
    <div class="box-login" style="display: block">
      <h2>登 录</h2>
      <p class="error-msg" v-show="errMsg" id="errorMsg">{{ errInfo }}</p>
      <form class="frm-login">
        <InputText
          label="手机号"
          type="text"
          placeholder="请输入手机号"
          @inputChange="(res) => (mobile = res)"
          @handlerErr="handlerErr"
        />
        <InputText
          label="密码"
          type="password"
          placeholder="请输入登录密码"
          @inputChange="(res) => (password = res)"
          @handlerErr="handlerErr"
        />
        <InputButton text="登录" @submitClick="submitClick"/>
        <p class="backPsd">
          <router-link :to="{ name: 'retrieve-password' }" class="col_666"
            >找回密码</router-link
          >
        </p>
      </form>
    </div>
    <!-- <div class="page-bottom">
      © 聚焦(北京)私募基金管理有限公司版权所有<a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer"> 京ICP备2022025266号</a>
    </div> -->

    <!-- 视频背景 -->
    <BgVedio />
  </div>
</template>

<script>
import BgVedio from "@/components/BgVedio";
import InputText from "./InputText.vue";
import InputButton from "./InputButton.vue";
import LogoLogin from "@/components/LogoLogin.vue";
export default {
  name: "Login",
  components: { BgVedio, InputText, InputButton, LogoLogin },
  data() {
    return {
      errMsg: false,
      errInfo: "",
      mobile: "",
      password: "",
    };
  },
  methods: {
    // 输入框获取焦点错误提示隐藏
    handlerErr() {
      this.errMsg = false;
      this.errInfo = "";
    },
    showErr(err){
        this.errInfo = err;
          this.errMsg = true;
    },
    // 单击登录按钮
    async submitClick() {
      try {
        let mobile = this.mobile.trim(),
            password = this.password.trim();

        if(!this.checkMobile(mobile)){
          this.showErr("请输入正确的手机号！");
          return false;
        }
        if(!this.checkPassword(password)){
          this.showErr("密码由6-18位英文,数字或字符（包含!@#$%^*_）组成!");
          return false;
        }
        let data={mobile,password}
        await this.$store.dispatch("userLogin", data);
        //登录成功，跳转到首页
        this.$router.push("/");
        
      } catch (error) {
        this.errInfo = error;
        this.errMsg = true;
      }
    },
     /**
     * 验证密码格式
     * @param  string 	password 密码
     * @return boolean	true/flase
     */
    checkPassword(password) {
        return (password.match(/[\w]([!@#$%^*]?){6,18}$/));
    },
     /**
     * 验证手机号码格式
     * @param  string 	mobile 手机号码
     * @return boolean	true/false
     */
    checkMobile(mobile) {
        return mobile.match(/^1[3-9][0-9]\d{8}$/);
    }

  },
};
</script>

<style scoped>
/* css-Login */
.page-login{width: 100vw;height: 100vh;position: relative;overflow: hidden;}
.box-login {
  display: none;
  padding: 0.42rem 0.45rem 0.25rem;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 6.94rem;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 0.08rem;
  box-shadow: 0.08rem 0.1rem 0.2rem 0 rgb(0 0 0 / 20%);
  box-sizing: border-box;
}
.box-login h2 {
  margin-top: 0rem;
  text-align: center;
  color: #333;
  font-size: 0.48rem;
  margin-bottom: 0.2rem;
  line-height: 1;
}
.frm-login {
  padding-top: 0.4rem;
  width: 95%;
  margin: 0 auto;
}
.error-msg {
  position: absolute;
    top: 1.1rem;
    right: 0;
    left: 0;
  margin: 0;
  color: #f84949;
  text-align: center;
  line-height: 1;
  height: .24rem;
}
.backPsd {
  padding-top: 0.3rem;
  padding-right: .1rem;
  width: 100%;
  text-align: right;
  font-size: 0.24rem;
}
.backPsd a {
  color: #666;
}
</style>
