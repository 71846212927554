import { render, staticRenderFns } from "./InputButton.vue?vue&type=template&id=6c6e73d2&scoped=true&"
import script from "./InputButton.vue?vue&type=script&lang=js&"
export * from "./InputButton.vue?vue&type=script&lang=js&"
import style0 from "./InputButton.vue?vue&type=style&index=0&id=6c6e73d2&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c6e73d2",
  null
  
)

export default component.exports