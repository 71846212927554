<template>
  <div class="button-group">
     <label class="col-form-label">&emsp;&emsp;</label>
      <input type="button" :value="text" @click="handler">
  </div>
</template>

<script>
export default {
  props: ["text"],
  data() {
    return {};
  },
  methods: {
      handler(){
          this.$emit("submitClick")
      }
  },
};
</script>

<style lang="css" scoped>
.button-group {
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 0.28rem;
display: flex;justify-content: space-between;align-items: center; 
  
}
.button-group  .col-form-label{padding-right: .3rem; color: #333;text-align: right;display:block;width: 5em;}
.button-group input {
    padding: 0 0.16rem;
  display: block;
  width: 4.2rem;
  height: 0.8rem;
  font-size:.32rem ;
  color: #fff;
  background: #5e0707;
    border-radius: .04rem;
    box-sizing: border-box;
}
</style>
