<template>
  <div class="input-group">
    <label class="col-form-label">
      <!-- 必选的星号 -->
      <span class="star" v-if="require">*</span>{{ label }}</label
    >
    <div class="btn-box">
      <!-- 验证码输入框 -->
      <input
        class="input-code"
        :type="type"
        :placeholder="placeholder"
        v-model="content"
        @focus="handlerErr"
      />
      <!-- 发送验证码按钮 -->
      <img class="ImgCode" :src="captcha" alt="" @click="handlerCode">
    </div>
  </div>
</template>

<script>
export default {
  name: "ImgCode",
  props: ["label", "type", "placeholder","require","captcha"],
  data() {
    return {
      // 验证码
      content:"",
    };
  },
  watch: {
    content() {
      this.handler();
    },
  },
  created() {
   
  },
  methods: {
   
    // 获取验证码并验证处理
    handler() {
       this.$emit("inputChange", this.content);
    },
    // 输入框获取焦点隐藏错误提示信息
    handlerErr() {
      this.$emit("handlerErr");
    },
    //点击倒计时回掉 
    async handlerCode() {
      this.$emit("getImgCode");
    },
   
  },
};
</script>
<style lang="css" scoped>
.input-group {
  position: relative;
  padding-bottom: 0.3rem;
  width: 100%;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;;
  align-items: center;
}
.btn-box {
  width: 4.53rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-group .col-form-label {
  padding-right: 0.3rem;
  color: #333;
  text-align: right;
  display: block;
  width: 6em;
}
.input-group input {
  display: block;
  padding: 0 0.16rem;
  height: 0.8rem;
  background: #fff;
  border: .01rem solid #b0b0b0;
  border-radius: .04rem;
  box-sizing: border-box;
}
.input-group input.input-code {
  width: 4.6rem;
}

.star {
  color: #6c1e14;
}
.ImgCode{
  border:none;
  outline: none;
  position: absolute;
  top: .01rem;
  right: 0.01rem;
  display: block;
  width: 1.5rem;
  height: .78rem;
  border-radius:0 .04rem .04rem 0;
  z-index: 22;
}
</style>
