<template>
  <div class="logo">
    <router-link to="/home">
      <img src="@/assets/logo_.png" alt="" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "LogoLogin",
  data() {
    return {};
  },
  methods: {},
  components: {},
};
</script>

<style lang='css' scoped>
/* 头部logo */
.logo {
  position: absolute;
  width: 100%;
  left: 0;
  top: 1.66rem;
  text-align: center;
}
.logo img {
  height: 0.78rem;
}
.logo a {
  display: block;
  height: 100%;
}
@media screen and (max-width:322px) {
  .logo{top: .7rem;}
  
}
</style>
