<template>
  <div class="page-top">
    <div class="logo">
      <router-link to="/home">
        <img src="@/assets/logo_.png" alt="" />
      </router-link>
    </div>
    <router-link class="text_btn" to="/home">返回首页</router-link>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {};
  },
  
};
</script>
<style lang="css"  scoped >
/* 头部 top */
.page-top {
  height: 1.5rem;
  padding: 0 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo a {
  display: block;
  height: 100%;
}
.logo img {
  display: block;
  height: 0.78rem;
}
.fir-ini {
  font-size: 0.16rem;
  color: #333;
  padding: 0 0.1rem;
  text-decoration: none;
}
.text_btn {
  color: #333;
  font-size: 0.2rem;
  padding-bottom: .3rem;
}
</style>
