<template>
  <div class="page-login">
    <LogoLogin v-if="step == 1" />
    <!-- 找回密码  步骤1 -->
    <Step1 @goStep2="goStep2" @changeVal="changeVal"  v-if="step == 1" />
    <!-- 找回密码  步骤2 -->
    <Step2 @goStepOk="goStepOk" v-if="step == 2" />
    <!-- 找回密码成功 步骤3 -->
    <Step3 v-if="step == 3" :mobile='mobile'/>
    <!-- 视频背景 -->
    <BgVedio />
    <!-- <div class="page-bottom">
      © 聚焦(北京)私募基金管理有限公司版权所有<a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer"> 京ICP备2022025266号</a>
    </div> -->
  </div>
</template>

<script>
import Step1 from "./Step1/index";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import BgVedio from "@/components/BgVedio";
import LogoLogin from "@/components/LogoLogin.vue";
export default {
  name: "retrieve-password",
  data() {
    return {
      step: 1,
      mobile: "",
      code: "",
      client: "",
      resPwd: "",
      pwd: "",
    };
  },
  components: {
    Step1,
    Step2,
    Step3,
    BgVedio,
    LogoLogin,
  },

  methods: {
    changeVal(val){
      Object.assign(this,val)
    },
    // 第一步完成，通知进入第二部
    goStep2(data) {
      console.log('888888',data)
      this.mobile = data.mobile;
      this.code = data.code;
      this.step = 2;
    },

    // 下一步2
    goStepOk(data) {
      this.pwd = data.pwd;
      this.resPwd = data.resPwd;
      this.rePassworld();
    },
    // 申请修改密码
    async rePassworld() {
      try {
        let { mobile, code, resPwd, pwd } = this;
        if (mobile && code && resPwd == pwd) {
          // 发起求改密码action
         await this.$store.dispatch("rePassworld", {
            mobile,
            code,
            resPwd,
            pwd,
          });
          this.step = 3;
        }
      } catch (error) {
        this.$toast(error);
        setTimeout(() => {
          this.$router.replace("/retrieve-password");
        }, 1000);
      }
    },
  },
};
</script>
<style lang='css' scoped>
.page-login{width: 100vw;height: 100vh;position: relative;overflow: hidden;}
.box-login {
  padding: 0.6rem 0.2rem 0.3rem;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 95%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0.08rem;
  box-shadow: 0.08rem 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
}
.frm-login {
  padding: 0.26rem 0.5rem 0;
}
.error-msg {
  margin: 0;
  color: #f84949;
  text-align: center;
  line-height: 1;
}
</style>