// 当前这个模块:API进行统一管理
import requests from "./request.js";

// 登录
export const reqUserLogin= (data)=>requests({url:'/login',method:'post',data});

// 退出登录 logout post token
export const reqLogOut=(data)=>requests({url:'/logout',method:'post',data});
// 刷新token
// api/refresh  post token
export const reqRefresh=(data)=>requests({url:"/refresh",method:'post',data});

// 获取未读消息数量 mock/messageCount post token
export const reqMessageCount=(data)=>requests({url:'/messageCount',method:'post',data});

// 发送验证码
// export const reqsendCode =(data)=>mockRequest({url:'/sendCode',method:'post',data});


// ===============个人中心=====================
// 我的投资列表：/investment/list   post  token page:1
export const reqInvestment=(data)=>requests({url:"/investment/list",method:"post",data});
// 用户信息:/user/info   post  token
export const reqUserInfo=(data)=>requests({url:"/user/info",method:"post",data});

//投资详情 /investment/info  post id,token
export const reqInvestmentInfo=(data)=>requests({url:"/investment/info",method:"post",data}); 
// 信息披露 /announce/list post token,id
export const reqAnnounceList=(data)=>requests({url:"/announce/list",method:"post",data});

// 交易记录 /deal/list  post token,page:1
export const reqTransitionList=(data)=> requests({url:"/deal/list",method:"post",data});
// 交易记录详情 /deal/info post id,token
export const reqTransactionInfo=(data)=>requests({url:"/deal/info",method:"post",data});

// 获取消息列表 /message/list post  token,page
export const reqMessageList=(data)=>requests({url:'/message/list',method:"post",data});
// 查看消息 /message/info post  token,id
export const reqMessageInfo =(data)=> requests({url:'/message/info',method:"post",data});
// 修改数据状态
export const reqMessageState=(data)=> requests({url:'/message/state',method:"post",data});
// =====================找回密码
// 图形验证码
export const  reqGetImgCode=()=>requests({url:"/img_code",method:'get'});
// 校验图形验证码 POST   img_code   client
export const reqCheckImgCode=(data)=>requests({url:"/check_img_code",method:'post',data})
// 获取验证码
export const reqSendCode=(data)=>requests({url:"/sendCode",method:'post',data});
// 修改密码 api/resetPwd post  mobile，pwd， rePwd， code
export const reqRePassworld=(data)=>requests({url:'/resetPwd',method:"post",data});
// =================测试接口=========

